
import $ from "./lib/import-jquery";

const sceneEl = document.querySelector('a-scene')
const video = $("#video")
const hintContent = $("#hintContent")
const scanning= $("#example-scanning-overlay")
const startIntro= $("#startIntro")

$("#close").on("click", event => {
  event.preventDefault()
  $("#content").hide()
  video.prop('currentTime', 0);
  video.trigger('pause');
  video.attr('src', "");
  hintContent.hide()
  scanning.show()
})

$("#startButton").on("click", event => {
    event.preventDefault()
    startIntro.hide()
})

// 添加瀏覽器提示
// https://github.com/withcloud/mindar-congratulations-picture-demo/blob/browser/src/index.html
const browser = {
  // 內核信息
  versions: (function () {
      const u = navigator.userAgent
      const app = navigator.appVersion
      return {
      trident: u.indexOf('Trident') > -1, // IE
      presto: u.indexOf('Presto') > -1, // opera
      webKit: u.indexOf('AppleWebKit') > -1, // webKit苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // firefox
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), // mobile
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android or uc
      iPhone: u.indexOf('iPhone') > -1, // iPhone QQHD
      iPad: u.indexOf('iPad') > -1, // iPad
      webApp: u.indexOf('Safari') == -1 // Safari、Chrome
      }
  })(),
  // 用戶首選語言的字串，通常是瀏覽器UI的語言
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  // 打開對象
  openObject: (function () {
      const ua = navigator.userAgent
      const u = navigator.userAgent.toLowerCase()
      return {
      weixin: u.match(/MicroMessenger/i) == 'micromessenger',//weibo
      qq: u.match(/QQ/i) == 'qq',//weibo
      weibo: u.match(/WeiBo/i) == 'weibo',//weibo
      alipay: /alipay/gi.test(u),//alipay
      dingtalk: u.indexOf('dingtalk') !== -1,//dingtalk
      chrome: u.indexOf('Chrome') > -1,//chrome
      isChrome: /(?:Chrome|CriOS)/.test(ua),
      isSafari: /Safari/.test(ua), // Safari
      baidui: u.indexOf('baidu') > 0, //baidui
      ios: /iphone|ipod|ipad/.test(u), //ios
      firefox: u.indexOf('firefox') > 0, //firefox
      edga: u.indexOf('edga') > 0, //edgA
      vivo: u.indexOf('vivo') > 0, //edgA
      }
  })()
}

// 微信檢查
// 偵測到微信時會 hasWarning
// 偵測到微信時會彈 alert
let hasWarning = false
$('#wxTips').css('display', 'none')
$('#recommend').css('display', 'none')
// 移動端
if (
  /Android|Adr|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
    if (browser.openObject.weixin) {
      hasWarning = true
      $('#wxTips').css('display', 'block')
      startIntro.hide()
    } else if (browser.openObject.firefox||browser.openObject.dingtalk|| browser.openObject.weibo || browser.openObject.qq ||browser.openObject.baidui||  browser.openObject.baidui||browser.openObject.vivo) 
      {
      hasWarning = true
      $('#recommend').css('display', 'block')
      startIntro.hide()
    } else if(browser.openObject.isChrome||browser.openObject.isSafari||browser.openObject.edga){
       // do nothing
    }else {
      // do nothing
    }
} else {
  // pc端
  // do nothing
}

// 觸碰播放情況
// 按按鈕時, initialized = true, 防止重複執行用
// 按按鈕時，播放和暫停影片
let initialized = false
$('body').on('click touchstart', function () {
  video.trigger('play');
});

sceneEl.addEventListener("arReady", (event) => {
  if (!hasWarning) {
     // do something
  }
});

sceneEl.addEventListener('arError', (event) => {
  if (event && event.detail && event.detail.error === 'VIDEO_FAIL') {
    if (hasWarning) {
      // 異常不處理
    } else {
      $('#permission-modal').removeClass('hidden')
      $('#mindar-ui-compatibility').hide()
      startIntro.hide()
    }
  }
})

// 禁用雙指放大
document.documentElement.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault()
  }
}, {passive: false})

// 禁用雙擊放大
let lastTouchEnd = 0
document.documentElement.addEventListener('touchend', function (event) {
  const now = Date.now()
  if (now - lastTouchEnd <= 300) {
    event?.preventDefault()
  }
  lastTouchEnd = now
}, {passive: false})

document.addEventListener('gesturestart', function (event) {
  event.preventDefault()
})

document.addEventListener('mousewheel', function (e) {
	e = e || window.event;
	if ((e.wheelDelta && event.ctrlKey) || e.detail) {
		event.preventDefault();
	}
}, {
	capture: false,
	passive: false
});


setTimeout(() => {
  document.getElementById('dummy').load()
}, 500)

// 添加屏幕轉向監控
const screenDirection = window.innerHeight > window.innerWidth
setInterval(() => {
  if (screenDirection !== window.innerHeight > window.innerWidth) {
    window.location.reload()
  }
}, 1000)

